import { css } from "@emotion/css";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { atom, useAtom } from "jotai";
import {
    AntennaIcon,
    CableIcon,
    ChevronDownIcon,
    ClapperboardIcon,
    HomeIcon,
    InfoIcon,
    LockIcon,
    MagnetIcon,
    KeyIcon,
    NotepadTextIcon,
    PencilRulerIcon,
    ServerIcon,
    SettingsIcon,
    SparkleIcon,
    Users2Icon,
    Wand2Icon,
} from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import {  getGlobalTriggerState, toggleTriggerEnabled } from "~/dataProcessor/api/api";
import { usePHFeatureFlag } from "~/dataProcessor/hooks/posthog";

import { Switch } from "~/design-system/atom/switch";
import { ToolTipBox } from "~/design-system/atom/tooltip";

import ComposioLogo from "~/ui/components/base/logo";
import { usePlainLogs } from "~/ui/screens/action_logs/logs";

import { AnimatePresence, motion } from "framer-motion";
import { If } from "~/utils/reactComponent";



export const Sidebar = React.memo(({ actionable = true }: { actionable?: boolean }) => {

    const { logsData } = usePlainLogs();
    const showPlayground = usePHFeatureFlag({ key: "onboarding-v2", defaultValue: { isEnabled: false, payload: null } });
    // @ts-ignore
    const isConnectorAndLogsLogsEmpty = useMemo(() => {
        return logsData?.length === 0;
    }, [logsData]);

    const sidebarStyle = clsx(
        "fixed flex h-[100vh] max-h-[100vh] flex-col",
        css`
            width: ${"264px"};
            transition: width 0.1s ease-in-out;

            .menu span,
            .hidden-text {
                visibility: ${"visible"};
                transition: visibility 10s cubic-bezier(0, 0, 1);
            }
        `,
        "sidebar",
    );

    const isLogsNotEmpty = useMemo(() => {
        return logsData?.length > 0;
    }, [logsData]);

    const links = useMemo(() => {
        return [
            ...(showPlayground.isEnabled ? [{
                link: "/playground",
                icon: <SparkleIcon height={13} width={13} strokeWidth={1.5} />,
                text: "Playground",
            }] : []),
            {
                link: "/dashboard",
                icon: <HomeIcon height={14} width={14} />,
                text: "Dashboard",
            },
            ...(isLogsNotEmpty
                ? [
                    {
                        link: isConnectorAndLogsLogsEmpty ? "/dashboard" : "/sdk_guide",
                        icon: <Wand2Icon type="solid" height={13} width={13} strokeWidth={2} />,
                        text: isConnectorAndLogsLogsEmpty ? "Get started" : "SDK Guide",
                    },
                ]
                : []),

            {
                link: "/apps",
                icon: <PencilRulerIcon height={13} width={13} strokeWidth={1.8} />,
                text: "Tools",
                children: [
                    {
                        link: "/apps",
                        icon: <ClapperboardIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "All tools",
                        disabled: isConnectorAndLogsLogsEmpty
                    },
 
                    {
                        link: "/your_apps",
                        icon: <ClapperboardIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "Integrations",
                        disabled: isConnectorAndLogsLogsEmpty
                    },
                    {
                        link: "/connections",
                        icon: <Users2Icon height={13} width={13} strokeWidth={1.8} stroke={"#000"} />,
                        text: "Connected accounts",
                        disabled: isConnectorAndLogsLogsEmpty
                    }, 
                    {
                        link: "/active_triggers",
                        icon: <AntennaIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "Active triggers",
                        disabled: isConnectorAndLogsLogsEmpty
                    },
                    // {
                    //     link: "/custom_tools",
                    //     icon: <PencilRulerIcon height={13} width={13} strokeWidth={1.8} />,
                    //     text: "Bring your tools",
                    // },
                    // {
                    //     link: "/machines",
                    //     icon: <ServerIcon height={13} width={13} strokeWidth={1.8} />,
                    //     text: "Machines",
                    // },
                ]

            },

     
            {
                link: "/logs",
                icon: <NotepadTextIcon height={13} width={13} strokeWidth={1.8} />,
                text: "Logs",
                disabled: isConnectorAndLogsLogsEmpty,
                children: [, {
                    link: "/logs",
                    icon: <NotepadTextIcon height={13} width={13} strokeWidth={1.8} />,
                    text: "Action logs",
                },{
                    link: "/trigger_logs",
                    icon: <NotepadTextIcon height={13} width={13} strokeWidth={1.8} />,
                    text: "Trigger logs",
                }]
            },

            {
                link: "/settings",
                icon: <SettingsIcon height={13} width={13} strokeWidth={1.8} />,
                text: "Settings",
                children: [{
                    link: "/settings",
                    icon: <KeyIcon height={13} width={13} strokeWidth={1.8} />,
                    text: "API Keys",
                },
                {
                    link: "/events_and_triggers",
                    icon: <AntennaIcon height={13} width={13} strokeWidth={1.8} />,
                    text: "Events and Triggers",
                },
                {
                    link: "/plan_settings",
                    icon: <PencilRulerIcon height={13} width={13} strokeWidth={1.8} />,
                    text: "Billing",
                },
                {
                    link: "/team_members",
                    icon: <Users2Icon height={13} width={13} strokeWidth={1.8} />,
                    text: "Manage team",
                }]
            },
        ]
    }, [isLogsNotEmpty])


    return (
        <div className={sidebarStyle}>
            <div className="flex w-full justify-between text-[#000] items-center">
            <ComposioLogo textColor={"#171717"} viewBox="0 0 33 33" height={20} width={28} className="ml-5 flex h-14 items-center pt-2" />
            </div>
            <div className="flex h-full flex-col px-[14px] pt-8">
                <MenuSection
                    // @ts-ignore
                    links={links}
                    actionable={actionable}
              
                />

               
            </div>
        </div>
    );
});

const TriggerState = () => {
    const { data: triggersInfo, refetch } = useQuery(["triggerState"], getGlobalTriggerState, {});
    const [areTriggersEnabled, setAreTriggersEnabled] = useState(false);

    useEffect(() => {
        setAreTriggersEnabled(!!triggersInfo?.triggersEnabled);
    }, [triggersInfo?.triggersEnabled]);

    const setTriggerState = (newState: boolean) => {
        setAreTriggersEnabled(!areTriggersEnabled);
        toggleTriggerEnabled(newState)
            .then(() => {
                refetch();
            })
            .catch((err) => {
                setAreTriggersEnabled(false);
                throw err;
            });
    };

    return (
        <div className="mb-[8px] rounded-[10px] border-[.5px] border-grey-200 bg-[#fff] px-3 py-[6px] pt-[7px] text-[12px] font-[500] text-gray-700 hover:opacity-80">
            <div className=" flex w-full items-center justify-between  gap-2 font-avenirn text-[8px] font-[600]">
                <div className="flex items-center gap-2 font-[500] text-[12px]">
                    <ToolTipBox content={areTriggersEnabled ? "Click toggle to stop receiving triggers" : "Click toggle to start receiving triggers via webhook"} side="top" align="start">
                        <div className="flex items-center gap-2"><CableIcon height={13} width={13} /> Webhook triggers  <InfoIcon height={13} width={13} /></div>
                    </ToolTipBox>
                </div>
                <div className="text-[12px] font-[500] text-grey-1000 underline">
                    <Switch
                        disabled={false}
                        checked={areTriggersEnabled}
                        className={clsx(
                            "h-[18px] w-[40px]",
                            css`
                                span {
                                    width: 12px;
                                    height: 12px;
                                }
                            `,
                        )}
                        onCheckedChange={(e) => {
                            console.log(e);
                            setTriggerState(!triggersInfo?.triggersEnabled);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const openMenuAtom = atom("");

const MenuSection = ({
    links,
}: {
    links: {
        link: string;
        icon: React.ReactNode;
        text: string;
        disabled?: boolean;
        children?: {
            link: string;
            icon: React.ReactNode;
            text: string;
            disabled?: boolean;
        }[];
    }[];
    actionable: boolean;
}) => {
    const [openMenu, setOpenMenu] = useAtom(openMenuAtom);
    const router = useRouter();
    useEffect(() => {

        const matchedLink = links.find(link => link.link === router.pathname || link.children?.some(child => child.link === router.pathname));
        if (matchedLink) {
            setOpenMenu(matchedLink.link);
        }
    }, [router.asPath, links]);
 
    return (
        <div className=" mb-3 flex flex-col gap-[10px] ">
            {links.map(({ link, icon, text, disabled, children }) => {
               // @ts-ignore
               return <MenuBlock {...{link, icon, text, disabled, childItems: children, openMenu}} key={link} />
            })}
        </div>
    );
}

const MenuBlock = ({ link, icon, text, disabled, childItems, openMenu}: {link: string, icon: React.ReactNode, text: string, disabled: boolean, childItems: {link: string, icon: React.ReactNode, text: string, disabled: boolean}[], openMenu: string}) => {
    const hasMenuChildren = childItems && childItems.length > 0;
    const isMenuOpen = openMenu === link;



   
    return <React.Fragment key={link}>
        <MenuLink hasMenuChildren={!!hasMenuChildren} isMenuOpen={isMenuOpen && !!hasMenuChildren} key={link} {...{ link, icon, text, disabled }} />
            <If condition={hasMenuChildren && isMenuOpen}>

            <div className="flex flex-col gap-[10px]">
                <div className="flex flex-col gap-[10px]">
                {hasMenuChildren && isMenuOpen && childItems.map(({ link, icon, text, disabled }) => {
                    return <div className="pl-2"><MenuLink hasMenuChildren={false} isMenuOpen={isMenuOpen} key={link} {...{ link, icon, text, disabled }} />   </div>;
                })}
                </div>
            </div>
       
        </If>




    </React.Fragment>
}



const MenuLink = ({
    hasMenuChildren,
    isMenuOpen,
    text,
    icon,
    link,

    onClick,
}: {
    hasMenuChildren: boolean;
    isMenuOpen: boolean;
    text: string;
    icon: React.ReactNode;
    link?: string;
    onClick?: () => void;

}) => {
    const { pathname } = useRouter();
    const isSelected = pathname === link && !hasMenuChildren;
 
 
    return (
        <Link
            href={ link || "#"}
            className={clsx( "cursor-pointer")}
            onClick={onClick}
        >
            <div
                className={clsx(
                    "menu flex h-[30px] flex-1 cursor-pointer items-center gap-2 rounded-[10px] px-[12px] py-[4.5px] text-[13.5px] font-[500] text-black-400",
                   
                    css`
                        border: .5px solid transparent;

                        ${isSelected &&
                        `
                         path,rect,circle,polyline{
                            stroke:#9537de;
                            
                        }
                        color: #000;
                        background-color: #fff;
                        box-shadow: lch(0 0 0 / 0.02) 0px 4px 4px -1px,lch(0 0 0 / 0.03) 0px 1px 1px 0px!important;
                        border: .5px solid #cacaca;
                        font-weight:500;`}
                        transition: all .18s linear;

                        :hover {
                            background: #fff;
                            border: .5px solid #dfdfdf;
                        }

             
                    `,
                )}
            >
                {icon}
                <span className="mt-[2px] tracking-[.45px]">{text}</span>
            
                
                {hasMenuChildren && <motion.div
                    initial={{ rotate: 0, marginLeft: "auto" }}
                animate={{ rotate: !isMenuOpen ? -0 : -180, marginLeft:"auto" }}
                style={{marginLeft: "auto"}}
                transition={{duration: 0.2}}
                ><ChevronDownIcon height={15} width={15} className={clsx("ml-auto transition-transform duration-100")} /></motion.div>}
            </div>
        </Link>
    );
};
