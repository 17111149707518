import { css } from "@emotion/css";

export const dustPatternCSS = css`
    background: url("/assets/images/global/dust.png") repeat center center;
    background-size: 24px;
    z-index: 0;
`;
export const gridPatternCSS = css`
    background: url("/assets/images/global/pattern.svg") repeat center center;
    background-size: 24px;
    opacity: 0.9;
    z-index: 0;
`;
export const gradientBackgroundCSS = css`
    background:
        0% 0% / 100px 100px repeat,
        linear-gradient(180deg, #000000 0%, #050505 73.91%);
`;

export const plainBlackBackgroundCSS = css`
    background: #f9f9f9;
    //background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(180deg, #F6F1FF -40%, #fff 100%);
background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(180deg, #fcfcfc 0%, #fafafa 40%);
//background: radial-gradient(55.71% 50.96% at 3.64% 0%, #EFF6FF 0%, rgba(252, 252, 252, 0.67) 40.65%, #FFF 100%), linear-gradient(180deg, #FCF2FF 0%, #FFF 50.5%, #F3FBFF 100%);
//background: radial-gradient(54.97% 37.89% at 1.03% 0%, #FFF7F4 0%, rgba(255, 247, 244, 0.67) 26.09%, #FFF 100%),;  
`;

export const scrollBarStyle = css`
    scrollbar-color: #0000003e #76767620;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
        width: 8px;
    }
`;

export const linkCSS = css`
    color: #ebebebec;
    transition: color 0.18s linear;
    :hover {
        color: #5d78ff;
    }
`;


export const borderAndShadowCSS = css`
   border-width: 0.5px !important;
   border-color: lch(85.2 0 282.863) !important;
   box-shadow: lch(0 0 0 / 0.02) 0px 4px 4px -1px, lch(0 0 0 / 0.03) 0px 1px 1px 0px !important;
`