import { useQuery } from "@tanstack/react-query";
import Strapi from "strapi-sdk-js";

const strapi = new Strapi({
    url: "http://127.0.0.1:1337/" || "https://strapi-production-2c8a.up.railway.app/api",

    axiosOptions: {
        headers: {
            Authorization:
                "Bearer 5bf45e5283f8ef5042f7ac5f045a8f6cc0a98e280c587f6ff0580d94ce9f94f05a3c4140ca56a7b25e414c2ba338bc562801042d20c87daf68c8e78b63b6cf4b4e3ca2f97cf8e815baa71055caf1c869fda10130801c4925260dd90ef361d319c0b938ab1cbdaabb93a69e28f00a1029eba28dd029c56143e41f1af2b1bcf1cf",
        },
    },
});

export const useStrapiData = (endpoint: string, params: Record<string, unknown> = {}) => {
    const { data, isLoading, error } = useQuery(
        [endpoint, params],
        async () => {
            return await strapi.find(endpoint, { ...params, populate: "*" });
        },
        {
            refetchOnWindowFocus: false,
            retry: 2,
        },
    );

    return {
        data,
        isLoading,
        error,
    };
};

export const prettifyString = (str: string | null | undefined): string | null | undefined => {
    if(!str) return str;

    return str
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
