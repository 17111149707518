import { string, z } from "zod";

import { BACKEND_URL } from "~/utils/baseUrl";
import { parseZodWithSentry } from "~/utils/zod";

import { axiosInstance } from "./global";
import backend from "~/client/sdk";
import { GetConnectorInfoResDTO, GetConnectorListResDTO } from "~/client/types.gen";
import sdk from "~/client/sdk";

export const ZConnectionInfoData = z.object({
    integrationId: z.string(),
    connectionParams: z.object({
        baseURL: z.string().optional(),
        client_id: z.string().optional(),
        client_secret: z.string().optional(),
        headers: z.record(z.string(), z.string()).optional(),
    }),
    id: z.string(),
    status: z.enum(["ACTIVE", "INITIATED", "SUCCESS"]),
    createdAt: z.string(),
    updatedAt: z.string(),
    clientUniqueUserId: z.string(),
    enabled: z.boolean().optional(),
    appUniqueId: z.string().optional(),
    meta: z
        .object({
            app: z.object({
                get_current_user_endpoint: z.string().nullish(),
            }),
        })
        .optional(),
});

export const getConnectionInfo = async ({ connectionID }: { connectionID: string }) => {
    return backend.connections
        .getConnection({
            path: {
                connectedAccountId: connectionID,
            }
        })
        .then((res) => res.data as unknown as any);
};

export const ZDeleteConnection = z.object({
    status: z.enum(["success", "failed"]),
    count: z.number().optional().nullable(),
});

export const deleteConnection = async ({ connectionID }: { connectionID: string }): Promise<z.infer<typeof ZDeleteConnection>> => {
    return backend.connections
        .deleteConnection({
            path: {
                connectedAccountId: connectionID,
            },
        })
        .then((res) => res.data)
        .then((data: any) => {
            if (data.count === 0) {
                throw new Error("Connection not found");
            }
            return data;
        });
};

// change isEnable to enable
export const toggleConnectionStatus = async ({ connectionID, isEnabled }: { connectionID: string; isEnabled: boolean }) => {
    if (!isEnabled) {
        return backend.connections.disableConnection({
            path: {
                connectedAccountId: connectionID,
            },
        });
    }
    return backend.connections.enableConnection({
        path: {
            connectedAccountId: connectionID,
        },
    });
};

export const getConnectorInfo = async ({ connectorId }: { connectorId: string }): Promise<GetConnectorInfoResDTO> => {
    return backend.appConnector
        .getConnectorInfo({
            path: {
                integrationId: connectorId,
            },
        })
        .then((res) => res.data!);
};

export const deleteConnector = async ({ connectorId }: { connectorId: string }) => {
    return backend.appConnector
        .deleteConnector({
            path: {
                integrationId: connectorId,
            },
        })
        .then((res) => res.data)
        .then((data: any) => {
            if (data.count === 0) {
                throw new Error("Integration not found");
            }
            return data;
        });
};

export const sendProxyConnectionRequest = async ({
    endpoint,
    input,
    connectedAccountId,
}: {
    endpoint: string;
    input: Record<string, unknown>;
    connectedAccountId: string;
}) => {
    return sdk.actionsV2
        .executeActionProxyV2({
            query:{
                endpoint,
                connectedAccountId
            },
            body: {
                input
            }
        })
        .then((res) => res.data);
};

export const ZIntegrationItemSchema = z.object({
    id: z.string(),
    clientId: z.string().optional(),
    member: z.any(),
    name: z.string(),
    authScheme: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    appId: z.string(),
    appName: z.string().optional(),
    logo: z.string().optional().nullish(),
    enabled: z.boolean().optional(),
    authConfig: z.any().optional(),
    useComposioAuth: z.boolean().optional(),
    _count: z.any().optional(),
});



export const ZConnectionItem = z.object({
    id: z.string(),
    integrationId: z.string().optional(),
    status: z.enum(["ACTIVE", "INITIATED", "SUCCESS"]),
    member: z.any(),
    connectionParams: z
        .object({
            scope: z.string().optional().nullable(),
            base_url: z.string().nullish(),
            client_id: z.string().optional(),
            token_type: z.string().optional(),
            access_token: z.string().optional(),
            client_secret: z.string().optional(),
        })
        .optional(),
    createdAt: z.string(),
    updatedAt: z.string(),
    invocationCount: z.string().optional(),
    clientUniqueUserId: z.string().optional().nullable(),
    appName: z.string(),
    isEnabled: z.boolean().optional(),
    isDisabled: z.boolean().optional(),
    logo: z.string().optional(),
    integrationIsDisabled: z.boolean().optional(),
    integrationDisabledReason: z.string().optional(),
});

export const ZConnectionsAPIResponse = z.object({
    items: z.array(ZConnectionItem),
    page: z.number(),
    totalPages: z.number(),
});

export const getClientIntegrations = (page = 1, appName: string, showDisabled: boolean): Promise<GetConnectorListResDTO> => {
    return sdk.appConnector
        .listAllConnectors({
            query: {
                page,
                appName,
                showDisabled,
                pageSize: 17,   
            },
        })
        .then((res) => res.data!);
};

export const ZTriggerInstanceItem = z.object({
    id: z.string(),
    connectionId: z.string(),
    triggerName: z.string(),
    triggerData: z.string().optional(),
    triggerConfig: z.object({
        repo: z.string().optional(),
        owner: z.string(),
    }),
    createdAt: z.string(),
    updatedAt: z.string(),
    disabledAt: z.string().nullable(),
});

export const ZTriggerInstanceResponseSchema = z.object({
    triggers: z.array(ZTriggerInstanceItem),
    pageInfo: z.object({
        currentPage: z.number(),
        perPage: z.number(),
        totalPages: z.number(),
    }),
});

export const getTriggerInstance = ({
    triggerNames,
    page,
    connectorId,
    connectionId,
    showDisabled = false,
}: {
    triggerNames?: string[];
    page?: number;
    connectorId?: string[];
    connectionId?: string[];
    showDisabled: boolean;
}) => {
    let queryString = "";
    const params = new URLSearchParams();
    if (triggerNames) params.append("triggerNames", triggerNames.join(","));
    if (page) params.append("page", page.toString());
    if (connectorId) params.append("integrationIds", connectorId.join(","));
    if (connectionId) params.append("connectedAccountIds", connectionId.join(","));
    if (showDisabled) {
        params.append("showDisabled", "true");
    }
    queryString = params.toString();

    const url = `${BACKEND_URL}/api/v1/triggers/active_triggers?${queryString}`;

    return axiosInstance
        .get(url, {
            withCredentials: true,
        })
        .then((res) => parseZodWithSentry(ZTriggerInstanceResponseSchema, res.data, url));
};

export const changeTriggerStatus = ({ triggerInstanceId, enabled }: { triggerInstanceId: string; enabled: boolean }) => {
    const url = `${BACKEND_URL}/api/v1/triggers/instance/${triggerInstanceId}/status`;

    return axiosInstance
        .post(url, {
            enabled: enabled,
        })
        .then((res) => res.data);
};

export const getConnections = ({
    connectorId,
    page,
    appName,
    status,
    showDisabled = true,
    user_uuid
}: {
    connectorId?: string;
    page?: number;
    appName?: string;
    status?: "ACTIVE" | "INITIATED" | "FAILED";
    showDisabled?: boolean;
    user_uuid?: string;
}) => {
    const queryObject = new URLSearchParams({
        ...(connectorId && {
            integrationId: connectorId,
        }),
        ...(!!page && {
            page: page.toString(),
        }),
        ...(!!appName && {
            appNames: appName,
        }),
        ...(!!status
            ? {
                  status: status.toString(),
              }
            : {}),
        ...{
            showDisabled: showDisabled.toString(),
        },
        ...(user_uuid && {
            user_uuid: user_uuid,
        }),
    });

    const queryString = queryObject.toString();
    const url = `${BACKEND_URL}/api/v1/connectedAccounts?${queryString}`;

    return axiosInstance
        .get(url, {
            withCredentials: true,
        })
        .then((res) => parseZodWithSentry(ZConnectionsAPIResponse, res.data, url));
};

export const getConnectorIntegrationInfo = ({ connectorId }: { connectorId?: string }) => {
    return sdk.appConnector
        .getConnectorInfo({
            path: {
                integrationId: connectorId as string,
            },
        })
        .then((res) => res.data);
};

export const ZActionItem = z.object({
    name: z.string(),
    display_name: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
    enabled: z.boolean().nullable().optional(),
    parameters: z
        .object({
            properties: z.record(z.any().optional()),
            required: z.array(z.string()).optional(),
            title: z.string(),
            type: z.string(),
        })
        .optional(),
    response: z
        .object({
            properties: z.any(),
            required: z.array(z.string()).optional(),
            title: z.string(),
            type: z.string(),
        })
        .optional(),
    logo: z.string().optional().nullish(),
    appKey: z.string(),
    appId: z.string(),
    appName: z.string(),
    enum: z.string(),
    type: z.string().optional(),
    tags: z.array(z.string()).optional(),
});

export const ZTriggerItem = z.object({
    name: z.string(),
    display_name: z.string(),
    description: z.string().nullable().optional(),
    enabled: z.boolean().nullable().optional(),
    config: z.any().optional(),
    payload: z.any().optional(),
    logo: z.string().optional(),
    count: z.number().optional(),
    appKey: z.string(),
    appId: z.string(),
    appName: z.string(),
    enum: z.string(),
    instructions: z.string().optional(),
    type: z.string().optional(),
});

const ZGetActionsResponse = z.object({
    items: z.array(ZActionItem),
    totalPages: z.number(),
    page: z.number(),
});

export const getActions = ({ appName, usecase, page = 1 }: { appName?: string; usecase?: string; page?: number }) => {
    return sdk.actionsV2.listActionsMinimalV2({
        query: {
            apps: appName,
            useCase: usecase,
            page: page.toString(),
        }
    }).then((res) => res.data).then((data) => {
        return {
            ...data,
            items: data?.items.filter(({deprecated}) => !deprecated)
        };
    });
};

const ZGetTriggersResponse = z.array(ZTriggerItem);

export const getTriggers = ({ appName, page = 1 }: { appName?: string; page?: number }) => {
    const url = `${BACKEND_URL}/api/v1/triggers?${!!appName ? `appNames=${appName}&` : ""}&page=${page}`;

    return axiosInstance
        .get(url, {
            withCredentials: true,
        })
        .then((res) => parseZodWithSentry(ZGetTriggersResponse, res.data, url));
};

export const ZConnectionInfo = z.object({
    id: z.string(),
    integrationId: z.string(),
    status: z.enum(["ACTIVE"]),
    logo: z.string().optional().nullish(),
    appName: z.string().optional(),
    data: z.object({
        scope: z.string().optional(),
        base_url: z.string().optional(),
        client_id: z.string().optional(),
        token_type: z.enum(["bearer", "Bearer", "bot"]).nullable().optional(),
        access_token: z.string().optional(),
        client_secret: z.string().optional(),
    }),
    createdAt: z.string(),
    updatedAt: z.string(),
});

export const createConnector = (appKey: string, data: Record<string, unknown>) => {
    const url = `${BACKEND_URL}/api/v1/app/${appKey}/connect`;

    return axiosInstance
        .post(url, data, {
            withCredentials: true,
        })
        .then((res) => res.data);
};

export const ZTriggerWebhookURL = z.object({
    status: z.enum(["success", "webhook_url_not_found"]),
    callbackURL: z.string().optional().nullable(),
});

export const getTriggerWebHookURL = (): Promise<z.infer<typeof ZTriggerWebhookURL>> => {
    const url = `${BACKEND_URL}/api/v1/triggers/callback_url`;

    return axiosInstance
        .get(url, {
            withCredentials: true,
        })
        .then((res) => parseZodWithSentry(ZTriggerWebhookURL, res.data, url));
};

export const setTriggerWebHookURL = (webhookURL: string) => {
    const url = `${BACKEND_URL}/api/v1/triggers/set_callback_url`;

    return axiosInstance
        .post(url, {
            callbackURL: webhookURL,
        })
        .then((res) => res.data);
};



export const getEventWebHookURL = (): Promise<Record<string, unknown>> => {
    const url = `${BACKEND_URL}/api/v1/event_logs/get/webhook`;

    return axiosInstance
        .get(url, {
            withCredentials: true,
        }).then((res) => res.data);
    
};

export const setEventWebHookURL = (eventWebhookURL: string) => {
    const url = `${BACKEND_URL}/api/v1/event_logs/set/webhook`;

    return axiosInstance
        .post(url, {
            eventWebhookURL: eventWebhookURL,
        })
        .then((res) => res.data);
};

export const ZDeleteTrigger = z.object({
    status: z.enum(["success", "failed"]),
    count: z.number().optional().nullable(),
});

export const deleteTriggerInstance = (triggerInstanceId: string): Promise<z.infer<typeof ZDeleteTrigger>> => {
    const url = `${BACKEND_URL}/api/v1/triggers/instance/${triggerInstanceId}`;
    return axiosInstance
        .delete(url)
        .then((res) => parseZodWithSentry(ZDeleteTrigger, res.data, url))
        .then((data) => {
            if (data.count === 0) {
                throw new Error("Trigger instance not found");
            }
            return data;
        });
};

export const ZCliAuth = z.object({
    cliCode: z.string(),
});

export const getCLICodeInExchangeForApp = ({ key }: { key: string }) => {
    const url = `${BACKEND_URL}/api/v1/cli/get-cli-code?key=${key}`;
    return axiosInstance.get(url).then((res) => parseZodWithSentry(ZCliAuth, res.data, url));
};

export const logoutUser = () => {
    const url = `${BACKEND_URL}/api/v1/client/auth/logout`;
    return axiosInstance.post(url);
};

export const ZToggleTriggerStateResponse = z.object({
    message: z.string(),
});

export const toggleTriggerEnabled = async (enabled: boolean): Promise<z.infer<typeof ZToggleTriggerStateResponse>> => {
    const url = `${BACKEND_URL}/api/v1/metadata/toggle/trigger`;

    return axiosInstance.post(url, { enabled: enabled }).then((res) => parseZodWithSentry(ZToggleTriggerStateResponse, res.data, url));
};

export const ZToggleInfoResponse = z.object({
    triggersEnabled: z.boolean(),
});

export const getGlobalTriggerState = async (): Promise<z.infer<typeof ZToggleInfoResponse>> => {
    const url = `${BACKEND_URL}/api/v1/metadata/toggle.info`;

    return axiosInstance.get(url).then((res) => parseZodWithSentry(ZToggleInfoResponse, res.data, url));
};

export const ZPostGoogleLoginResponse = z.object({
    clientId: z.string(),
    apiKey: z.string(),
    email: z.string()
});

export const postGoogleLogin = async (data: {jwt?: string; authCode?: string;}, type: "auth-code" | "jwt"): Promise<z.infer<typeof ZPostGoogleLoginResponse>> => {
    const url = `${BACKEND_URL}/api/v1/client/auth/google_login`;
    return axiosInstance
        .post(url, { data, type })
        .then((res) => parseZodWithSentry(ZPostGoogleLoginResponse, res.data, url));
};

export const ZSingleTriggerResDTO = z.object({
    name: z.string(),
    displayName: z.string(),
    description: z.string(),
    type: z.string(),
    enum: z.string(),
    appId: z.string(),
    appName: z.string(),
    instructions: z.string().optional(),
    payload: z.record(z.unknown()),
    config: z.record(z.unknown()),
});

export const getTriggerInfo = async (triggerName: string): Promise<z.infer<typeof ZSingleTriggerResDTO>> => {
    const url = `${BACKEND_URL}/api/v2/triggers/${triggerName}`;
    return axiosInstance
        .get(url)
        .then((res) => parseZodWithSentry(ZSingleTriggerResDTO, res.data, url));
};

export const ZGetActionResDTO = z.array(z.object({
    name: z.string().optional(),
    logo: z.string().optional(),
    enabled: z.boolean().optional(),
    enum: z.string().optional(),
    appId: z.string().optional(),
    appName: z.string().optional(),
    appKey: z.string().optional(),
    displayName: z.string().optional(),
    description: z.string().optional(),
    tags: z.array(z.string()).optional(),
    parameters: z.record(z.unknown()).optional(),
    response: z.record(z.unknown()).optional(),
}));

export const getActionInfo = async (actionName: string): Promise<z.infer<typeof ZGetActionResDTO>[0]> => {
    const url = `${BACKEND_URL}/api/v1/actions/${actionName}`;
    return axiosInstance
        .get(url)
        .then((res) => parseZodWithSentry(ZGetActionResDTO, res.data, url)).then((res) => {
            if(!res[0]) {
                throw new Error("No such action found");
            }
            return res[0];
        })
};

export const createCheckoutSession = (payload: {plan: string}) => {
    const url = `${BACKEND_URL}/api/v1/payment/create-checkout-session`;
    return axiosInstance.post(url, payload);
}

export const getCheckoutSessionStatus = (sessionId: string) => {
    const url = `${BACKEND_URL}/api/v1/payment/checkout-session/${sessionId}/status`;
    return axiosInstance.get(url);
}

export const getInvoices = () => {
    const url = `${BACKEND_URL}/api/v1/payment/invoices`;
    const response = axiosInstance.get(url);
    return response.then((res) => res.data);
}

export const getClientInfo = () => {
    return sdk.clientAuthService.getUserInfo();
}

export const getTopEntities = (query = "") => {
    return sdk.analytics.getTopEntities({
        query: {
            query
        }
    }).then((res) => res.data);
}