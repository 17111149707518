import { css } from "@emotion/css";
import clsx from "clsx";
import { XCircleIcon } from "lucide-react";
import React from "react";
import JsonView from "react18-json-view";

import { AlertDialogBoxCustom, AlertDialogDescription, AlertDialogHeader, AlertDialogTitle } from "~/design-system/atom/alert-dialog";
import { scrollBarStyle } from "~/ui/style/common";

export const LogSliderInfo = ({
    setOpen,
    logData,
    actionName,
}: {
    setOpen: (e: boolean) => void;
    open?: boolean;
    logData: any;
    actionName: string;
}) => {
    const logMetaData = logData.meta.data;
    const isTrigger = logData.type === "trigger";

    return (
        <AlertDialogBoxCustom
            open={true}
            setOpen={setOpen.bind(this)}
            className={clsx(
                " left-[undefined] right-[0%] h-[100vh] min-w-[580px] overflow-y-scroll !rounded-[0px] bg-[#fff] px-8 py-7 ",
                scrollBarStyle,
                css`
                    transform: translateY(-50%);
                `,
            )}
            isAnimating={false}
        >
            <div
                className="hover:bg-red absolute right-[16px] top-[28px] h-6 w-6 cursor-pointer rounded-[8px] text-black-300"
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(false);
                }}
            >
                <XCircleIcon height={16} width={16} />
            </div>
            <AlertDialogHeader>
                <AlertDialogTitle className="mt-1 font-avenirn text-[17px] font-[600] leading-none  text-black-400">
                    <div className="flex items-center gap-4 leading-none ">Log Info</div>
                </AlertDialogTitle>
                <AlertDialogDescription className="!mt-[10px]  font-gilroy text-[13px]  text-black-400">
                    {!isTrigger ? (
                        <>
                            <Field name="log id" content={logMetaData.id} type="text" />
                            <Field name="Time" content={logMetaData.createdAt} type="text" />
                            <Field name="Provider name" content={logMetaData.provider} type="text" />
                            <Field name="Action name" content={actionName} type="text" />
                            <Field name="Account Id" content={logMetaData.connectionId} type="text" />
                            <Field name="Request" content={!!logMetaData.request ? JSON.parse(logMetaData.request) : {}} type="json" />
                            <Field name="Response" content={!!logMetaData.response ? JSON.parse(logMetaData.response) : {}} type="json" />
                            <Field
                                name="Error request"
                                content={!!logMetaData.errorRequest ? JSON.parse(logMetaData.errorRequest) : {}}
                                type="json"
                            />
                        </>
                    ) : null}
                    {isTrigger ? (
                        <>
                            <Field name="Trigger ID" content={logMetaData.triggerId} type="text" />
                            <Field name="Trigger Name" content={logMetaData.triggerName} type="text" />
                            <Field name="Client ID" content={logMetaData.clientId} type="text" />
                            <Field name="Connection ID" content={logMetaData.connectionId} type="text" />
                            <Field name="Time" content={logMetaData.createdAt} type="text" />
                            <Field
                                name="Trigger Client Response"
                                content={!!logMetaData.triggerClientResponse ? JSON.parse(logMetaData.triggerClientResponse) : {}}
                                type="json"
                            />
                            <Field name="Trigger Processing Error " content={logMetaData.errorTrigger} type="json" />
                           
                            <Field
                                name="Trigger Client Response"
                                content={logMetaData.triggerClientPayload ? JSON.parse(logMetaData.triggerClientPayload) : {}}
                                type="json"
                            />
                            <Field
                                name="Trigger Provider Payload"
                                content={logMetaData.triggerProviderPayload ? JSON.parse(logMetaData.triggerProviderPayload) : {}}
                                type="json"
                            />
                        </>
                    ) : null}
                </AlertDialogDescription>
            </AlertDialogHeader>
        </AlertDialogBoxCustom>
    );
};

export const Field = ({ name, content, type }: { name: string; content: any; type: "json" | "text" }) => {
    return (
        <div className="border-b-[.5px] border-grey-400 pb-4">
            <div className="mb-1 mb-2 mt-5 text-[12px] font-[600] text-black-400 ">{name}</div>
            {type === "json" ? <JsonView src={content} className="rounded-[16px] border-[1px] border-grey-300 bg-[#fff] p-4" /> : null}
            {type === "text" ? <div className="text-[14px] text-black-600">{content as string}</div> : null}
        </div>
    );
};
