import clsx from "clsx";

import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "~/design-system/atom/select";

export const SimpleDropdown = ({
    options,
    onChange,
    value,
    placeholder = "Select value",
    className = "",
    size = 32,
}: {
    options: {
        name: string;
        value: string;
    }[];
    onChange: (value: string) => void;
    value: string;
    className?: string;
    placeholder?: string;
    size?: 32 | 36 | 40;
    rightIcon?: React.ReactNode;
}) => {
    return (
        <Select
            onValueChange={(value) => {
                onChange(value);
            }}
            value={value}
            autoComplete="true"
        >
            <SelectTrigger
                className={clsx(
                    "relativ  w-[200px]    overflow-hidden text-[13px] text-black-300",
                    `
                h-[${size}px]
            `,
                    className,
                )}
            >
                <SelectValue placeholder={placeholder} className=" w-full overflow-hidden" />
            </SelectTrigger>
            <SelectContent className="z-[200] border-grey-200 bg-white">
                <SelectGroup>
                    {options?.map((option) => (
                        <SelectItem value={option.value} key={option.value}>
                            {option.name}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    );
};
