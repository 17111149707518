import { format } from "date-fns";

export const formatDate = (date: string, fomrat = "standard") => {
    if (fomrat === "standard") {
        return format(
            new Date(date),
            new Date().getFullYear() === new Date(date).getFullYear() ? "MMM dd - HH:mm:ss" : "MMM dd, yy - HH:mm:ss",
        );
    }

    return format(new Date(date), "MMM dd, yy - HH:mm:ss");
};
