import { useRouter } from "next/router";

export const UpdateQuery = () => {
    const router = useRouter();

    const updateQueryFn = (key: string, value: string): Promise<boolean> => {
        const query = value ? { ...router.query, [key]: value } : { ...router.query };
        if (value === "") {
            delete query[key];
        }

        return router.push({
            query,
        });
    };

    return updateQueryFn;
};

export const UpdateQueryBatch = () => {
    const router = useRouter();

    const updateQueryFn = (updates: Record<string, string>): Promise<boolean> => {
        const query = { ...router.query };

        Object.entries(updates).forEach(([key, value]) => {
            if (value === "") {
                delete query[key];
            } else {
                query[key] = value;
            }
        });

        return router.push({
            query,
        });
    };
    return updateQueryFn;
};
