import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";

import { cn } from "~/utils/cn";

const Switch = React.forwardRef<
    React.ElementRef<typeof SwitchPrimitives.Root>,
    React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
    <SwitchPrimitives.Root
        className={cn(
            " focus-visible:ring-ring focus-visible:ring-offset-background peer inline-flex h-[22px] w-[40px] shrink-0 cursor-pointer items-center rounded-full border-[1px]  transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-80 data-[state=unchecked]:border-[1px] data-[state=unchecked]:border-grey-300 data-[state=checked]:bg-[#35BC20] data-[state=unchecked]:bg-[#fff]",
            className,
        )}
        {...props}
        ref={ref}
    >
        <SwitchPrimitives.Thumb
            className={cn(
                "pointer-events-none ml-1 block h-[14px] w-[14px] rounded-full shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-[18px] data-[state=unchecked]:translate-x-0 data-[state=checked]:bg-[#000] data-[state=checked]:bg-[#fff] data-[state=unchecked]:bg-[#000]",
            )}
        />
    </SwitchPrimitives.Root>
));

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
