import { css } from "@emotion/css";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { ChevronRightIcon, ExternalLinkIcon, HelpCircleIcon } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";

import { getClientInfo, logoutUser } from "~/dataProcessor/api/api";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "~/design-system/atom/dropdown-menu";
import { scrollBarStyle } from "~/ui/style/common";
import { eraseCookie, getCookie, setCookie } from "~/utils/cookie";
import { upperCaseFirstLetter } from "~/utils/string";
import { ANALYTICS } from "~/utils/tracking";
import { getGravatarUrl } from "~/utils/user";

export const MainContentTopBar = ({ header,headerLinks }: { header?: string | React.ReactNode,headerLinks?:{link:string,text:string,icon:React.ReactNode}[] }) => {
    const router = useRouter();
    const {data: clientInfo, isLoading} = useQuery({
        queryKey: ["clientInfo"],
        queryFn: () => getClientInfo(),
    });
    return (
        <div
            className={clsx(
                "sticky z-[10] flex h-[42px] min-w-[100%] items-center justify-between border-b-[.5px]  border-b-[#DBDBDB] bg-[#fff] px-6 pr-2",
                css`
                    position: sticky;
                    position: -webkit-sticky;
                    top: 0; /* required */
                    width: (100vw - 280px + 16px);
                `,
            )}
        >
            <div className="flex w-full flex-1 items-center font-avenirn text-[#2c2c2c] tracking-[0.3px] text-[12.4px] font-[500] ">
                <IconBadge icon="🧰"/>
                <Link href="/" className=" underline-offset-4 hover:underline font-[500]">
                    Home
                </Link>
                
                {!!headerLinks?.length ? (
                    <div className="flex items-center">
                        {headerLinks.map((link) => (
                            <div key={link.text} className="flex items-center gap-1">
                                <ChevronRightIcon height={12} width={12} strokeWidth={1.2} className="ml-[2px] mr-[0px]" color="#2c2c2c80" />
                                <Link href={link.link}>
                                    <div className="flex items-center gap-1 hover:underline">
                                        {link.icon}
                                        <span>{link.text}</span>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : !!header ? (
                    <>
                        <ChevronRightIcon height={12} width={12} strokeWidth={1.2} className="mx-[2px] mr-[3px]" color="#2c2c2c80" />
                        <span>{header}</span>
                    </>
                ) : null}
            </div>
            <div className="flex items-center gap-2">
                <Link href="/plan_settings">
                <div className={clsx("flex items-center px-4 py-1 text-[12px] font-[500] text-white bg-blue-500 rounded-[12px]", pricinPlanCSS)}>
                        {(clientInfo?.data as any)?.client.plan && (clientInfo?.data as any)?.client.plan !== "HOBBY" ? `⭐ ${upperCaseFirstLetter((clientInfo?.data as any)?.client.plan.toLowerCase())} Plan  ` : "🎾 Hobby Plan"}
                </div>
                </Link>
            </div>
            <div className="mr-4 ml-[6px] flex items-center ">
                <DocsAndSupport/>
                <ProfileBadge />
            </div>
        </div>
    );
};

const pricinPlanCSS = css`
background: rgba(240, 240, 240, 0.21);
    padding: 1px 8px;
    height: 28px;
    border: 0.5px solid rgb(202, 202, 202);
    color: rgb(64, 64, 64);
    border-radius: 8px;
    transition: 0.25s ease-in-out;
    font-weight: 500;
    letter-spacing: 0.3px;
    font-family: Gilroy, sans-serif;
    cursor: default !important;
    border-radius: 100px;
`

export const IconBadge = ({icon}: {icon: string}) => {
    return (
        <div aria-hidden="true" className={css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    word-break: keep-all;
    font-family: var(--font-emoji);
    width: 20px;
    height: 20px;
    background-color: rgba(90, 92, 95, 0.02);
    margin-right: 8px;
    padding: 3px;
    font-size: 9px !important;
    border: .5px solid #8f8f8f6b;
    border-radius: 6px;
`} data-type="emoji">{icon}</div>
    )
}

export const DocsAndSupport = ({height}: {height?: string})=>{
    return (
        <>
            <Link href="https://docs.composio.dev" target="_blank">
                <div className={clsx(" flex items-center gap-[6px] pr-4 mr-1 text-[13px] hover:underline", linkBox({height: height || "28px"}), docsLink)}>
                    <span className="mt-[2.2px]">Docs</span>
                    <ExternalLinkIcon color="#fff" height={13} width={13} strokeWidth={1.8} />
                </div>
            </Link>
            <Link href="https://dub.composio.dev/discord" target="_blank">
                <div className={clsx("ml-1 flex items-center gap-[6px] pr-4 mr-1 text-[13px] hover:underline", linkBox({height: height || "28px"}))}>
                    <HelpCircleIcon color="#404040" height={14} width={14} strokeWidth={1.8} />
                    <span className="mt-[1.5px]">Support</span>

                </div>
            </Link>
        </>
    )
}

const linkBox = (props: {height?: string})=>css`
    background: #f0f0f036;
    padding: 1px ${props.height ? "12px" : "8px"};
    height: ${props.height || "28px"};
    
    border: .5px solid #cacaca;
    color: #404040;
    border-radius: 8px;
    box-shadow: lch(0 0 0 / 0.02) 0px 4px 4px -1px,lch(0 0 0 / 0.03) 0px 1px 1px 0px!important;
     :hover{
        background: #F4F4F4 ;

        box-shadow: lch(0 0 0 / 0.05) 0px 4px 4px -1px,lch(0 0 0 / 0.05) 0px 1px 1px 0px!important;
    }
         cursor: default !important;
         transition: all 0.25s ease-in-out;
    font-weight: 500;
    letter-spacing: 0.3px;
    font-family: 'Gilroy', sans-serif;
`

const docsLink = css`
    background: #1e1e1e !important;
    border: .5px solid #8f8f8f6b;
    color: #ececec !important; 
    box-shadow: lch(0 0 0 / 0.02) 0px 4px 4px -1px,lch(0 0 0 / 0.03) 0px 1px 1px 0px!important;
    :hover{
     background: #292829 !important;
        box-shadow: lch(0 0 0 / 0.15) 0px 4px 4px -1px,lch(0 0 0 / 0.15) 0px 1px 1px 0px!important;
    }
        transition: all 0.25s ease-in-out;
        cursor: default !important;
        font-weight: 500;
`

export const ProfileBadge = () => {
    // TODO: Add logout button
    const gravatarURL = useMemo(() => getGravatarUrl(), []);

    const useEmail = useMemo(() => {
        return getCookie("composio_email");
    }, []);

    return (
        <DropdownMenu>
            <DropdownMenuTrigger>
                {" "}
                <img
                    height={32}
                    width={32}
                    src={gravatarURL}
                    alt="Gravatar"
                    className={clsx(    " h-[24px] min-h-[24px] w-[24px] min-w-[24px] rounded-full opacity-[90] hover:opacity-70",
                    css`
                        border: 1px solid #3D3D3D;
                        box-shadow: lch(0 0 0 / 0.02) 0px 4px 4px -1px,lch(0 0 0 / 0.03) 0px 1px 1px 0px!important;
                        
                    `)}
                />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-4" sideOffset={20}>
                <DropdownMenuLabel className="pr-4 !text-[11px] font-[400]  text-black-300">{useEmail}</DropdownMenuLabel>
                <DropdownMenuSeparator className="mx-2 bg-[#C7C7C7]" />
                <Link href="https://dub.composio.dev/discord" target="_blank">
                    <DropdownMenuItem>Join discord</DropdownMenuItem>
                </Link>
                <DropdownMenuItem
                    onClick={async () => {
                        onLogout();
                    }}
                >
                    Logout
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export const onLogout = () => {
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    logoutUser();
    setCookie("isLoggedIn", "false", 0);
    eraseCookie("composio_analytics_id");
    eraseCookie("composio_email");

    const logoutURLs = {
        "localhost": "/",
        "qa.composio.dev": "https://qa.composio.dev/logout",
        "composio.dev": "https://composio.dev/logout"
    };

    let redirectURL = logoutURLs[hostname as keyof typeof logoutURLs] || `${protocol}//${hostname}`;

    window.location.href = redirectURL;
}

export const RightSideContent = ({
    header,
    headerLinks,
    children,
    showTopBar = true,
    className
}: {
    header?: string | React.ReactNode;
    children: React.ReactNode;
    showTopBar?: boolean;
    className?: string;
    headerLinks?:{link:string,text:string,icon:React.ReactNode}[];

}) => {
    return (
        <div
            className={clsx(
                " ml-[264px]  ",
                scrollBarStyle,
                css`
                    width: calc(100vw - 280px + 16px);
                    background: #fff;
                    margin-top: 10px;
                    position: fixed;
                    height: calc(100vh - 12px);
                    border-left: 1px solid #ebebeb;
                    border-top: 1px solid #ebebeb;

                    border-left: .5px solid #d1cfcf;
                    border-top: .5px solid #d1cfcf;
                    
                    border-radius: 6px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    overflow-y: auto;

                    box-shadow: lch(0 0 0 / 0.01) 0px 4px 4px 0px,lch(0 0 0 / 0.02) 0px 4px 4px 0px!important;

                
                   
                `,
                className,
             
            )}
            id="scroll-box"
        >
            {showTopBar && <MainContentTopBar header={header} headerLinks={headerLinks} />}
            {children}
        </div>
    );
};
