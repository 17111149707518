"use client"

import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "~/utils/cn"
import { Button } from "~/design-system/atom/button"
import {
    Command,
    CommandItem,
    CommandList,
} from "~/design-system/atom/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "~/design-system/atom/popover"
import { Input } from "./input"
import { If } from "~/utils/reactComponent"
import clsx from "clsx"
import { css } from "@emotion/css"
import { LoadingIcon } from "~/ui/components/base/loadingIcon"

const frameworks = [
    {
        value: "next.js",
        label: "Next.js",
    },
    {
        value: "sveltekit",
        label: "SvelteKit",
    },
    {
        value: "nuxt.js",
        label: "Nuxt.js",
    },
    {
        value: "remix",
        label: "Remix",
    },
    {
        value: "astro",
        label: "Astro",
    },
]

export function Combobox({
    data= [],
    onChange,
    selectedValue,
    onInputChange,
    content = {
        emptyText: "No results found",
        loadingText: "Loading...",
        placeholder: ".",
    },
    isLoading = false,
}: {
    data: {value: string, label: string}[];
    onInputChange: (value: string) => void;
    onChange: (value: string) => void;
    selectedValue: string;
    isLoading: boolean;
    content: {
        emptyText: string;
        loadingText: string;
        placeholder: string;
    }
}) {
    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState("")

    React.useEffect(() => {
        setValue(selectedValue)
    }, [selectedValue])


   
    const newData = React.useMemo(() => {
        const findValueInData = data.find((item) => item.value === selectedValue)
        if (!isLoading && !!selectedValue && !findValueInData){
            data.unshift({ value: selectedValue, label: selectedValue })
        }

        return data

    }, [data, value, isLoading])

    const labelToShowOnButton = data.find((item) => item.value === selectedValue)?.label 

    
    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="plain"
                    role="combobox"
                    aria-expanded={open}
                    className="w-[full] !h-[32px] !rounded-[8px] border-[.5px] border-[#DBDBDB] justify-between min-w-[120px]"
                >
                    {!!labelToShowOnButton ? labelToShowOnButton : !selectedValue ? content.placeholder : "Select"}
                    <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[160px] p-0 bg-[#fff] pb-[8px]">
                <Command>
                  <div className="px-[8px] pt-[8px] mb-2">
                        <Input placeholder={"Search"} className={clsx("!h-[32px] !rounded-[8px] border-[.5px] border-[#DBDBDB] justify-between", css`
                            box-shadow: none !important;
                            `)} onChange={(e) => onInputChange(e.target.value)} />
                  </div>
                    <CommandList >
            
        
                         
                        <div className="px-1">
                            {newData.map((item) => (
                                <CommandItem
                                    key={item.value}
                                    value={item.value}
                                    onSelect={(currentValue) => {
                                        setValue(currentValue === value ? "" : currentValue)
                                        onChange(currentValue === value ? "" : currentValue)
                                        setOpen(false)
                                    }}
                                    
                                    
                                >
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            selectedValue === item.value  ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                    {item.label || "No label"}
                                </CommandItem>
                            ))}
                        </div>

                        <If condition={data.length === 0 && !isLoading}>
                            <div className="px-1 py-3 text-center text-[#808080]">{content.emptyText}</div>
                        </If>
                        <If condition={isLoading}>
                            <div className="flex gap-1 justify-center items-center px-1 py-3 text-[#808080]"><LoadingIcon height={16} width={16} /> Loadding...</div>
                        </If>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}