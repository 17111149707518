import { css } from "@emotion/css";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import * as React from "react";

import { buttonVariants } from "~/design-system/atom/button";
import { cn } from "~/utils/cn";

const AlertDialog = AlertDialogPrimitive.Root;

const AlertDialogTrigger = AlertDialogPrimitive.Trigger;

const AlertDialogPortal = AlertDialogPrimitive.Portal;

const AlertDialogOverlay = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, ...props }, ref) => {
    return (
        <AlertDialogPrimitive.Overlay
            className={cn(
                "fixed inset-0 z-[100] bg-[#000000d4]  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
                className,
                css`
                    backdrop-filter: blur(6.5px);
                `,
            )}
            {...props}
            ref={ref}
        />
    );
});

AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;

const AlertDialogContent = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content> & {
        isAnimating?: boolean;
    }
>(({ className, isAnimating = true, ...props }, ref) => {
    return (
        <AlertDialogPortal>
            <AlertDialogOverlay
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    //@ts-ignore
                    props?.onFocusOutside?.(e);
                }}
            />
            <AlertDialogPrimitive.Content
                ref={ref}
                className={cn(
                    "sm:rounded-lg fixed left-[50%] top-[50%] z-[101] grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 !rounded-[20px] border border-[#242424d1] bg-[#fff] bg-[#fff] p-6 shadow-lg ",
                    isAnimating
                        ? "duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]"
                        : "",
                    className,
                )}
                {...props}
            />
        </AlertDialogPortal>
    );
});

AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;

const AlertDialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn("sm:text-left flex flex-col space-y-1", className)} {...props} />
);

AlertDialogHeader.displayName = "AlertDialogHeader";
// Custom Component for AlertDialog
// it is used to wrap AlertDialogContent and AlertDialogOverlay
export const AlertDialogBoxCustom = ({
    setOpen,
    children,
    className,
    isAnimating,
}: {
    open: boolean;
    setOpen: (e: boolean) => void;
    children: React.ReactNode;
    className?: string;
    isAnimating?: boolean;
}) => {
    return (
        <AlertDialog open={true} onOpenChange={() => setOpen(false)}>
            <AlertDialogContent
                onFocusOutside={(e) => {
                    e?.stopPropagation();
                    setOpen(false);
                }}
                className={className}
                isAnimating={isAnimating}
            >
                {children}
            </AlertDialogContent>
        </AlertDialog>
    );
};

const AlertDialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn("sm:flex-row sm:justify-end sm:space-x-2 flex flex-col-reverse", className)} {...props} />
);

AlertDialogFooter.displayName = "AlertDialogFooter";

const AlertDialogTitle = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title>
>(({ className, ...props }, ref) => <AlertDialogPrimitive.Title ref={ref} className={cn("text-lg font-semibold", className)} {...props} />);

AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;

const AlertDialogDescription = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description>
>(({ className, ...props }, ref) => <div ref={ref} className={cn("text-muted-foreground text-sm", className)} {...props} />);

AlertDialogDescription.displayName = AlertDialogPrimitive.Description.displayName;

const AlertDialogAction = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Action>,
    React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action>
>(({ className, ...props }, ref) => (
    <AlertDialogPrimitive.Action
        ref={ref}
        className={cn(
            buttonVariants({
                size: 40,
            }),
            "g-gradient-to-b h-[38px] cursor-pointer from-[#3875e5] to-[#2238FF] text-primary-foreground hover:bg-gradient-to-b hover:brightness-90",
            className,
        )}
        {...props}
    />
));

AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName;

const AlertDialogCancel = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
    React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel>
>(({ className, ...props }, ref) => (
    <AlertDialogPrimitive.Cancel
        ref={ref}
        className={cn(
            buttonVariants({
                variant: "plain",
            }),
            "sm:mt-0 mt-2 h-[32px] ",
            className,
        )}
        {...props}
    />
));

AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;

export {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogTitle,
    AlertDialogTrigger,
};
