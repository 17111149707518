import { css } from "@emotion/css";
import clsx from "clsx";
import { ChevronLeftIcon, ChevronRightIcon, SatelliteDishIcon, ShapesIcon, XOctagonIcon } from "lucide-react";
import React from "react";

import { Button } from "~/design-system/atom/button";
import { LoadingIcon, LoadingIconPlain } from "~/ui/components/base/loadingIcon";

export const LoadingState = ({ height, text = "Loading..." }: { height: number; text?: string }) => {
    return (
        <div
            className={clsx(
                "flex max-h-full  w-full flex-1 flex-col  items-center justify-center gap-3 space-x-4 py-4 text-[14px]  text-black-500",
                height
                    ? css`
                          height: ${height}px;
                      `
                    : "max-h-[calc(100vh-300px)]",
            )}
        >
            <LoadingIcon height={32} width={32} strokeWidth={1.3} />
            {text}
        </div>
    );
};

export const NoResult = ({ text, height }: { text: string | React.ReactNode; height: number }) => (
    <div
        className={clsx(
            "flex  max-h-full w-full flex-1 flex-col items-center justify-center gap-4 py-4 text-[14px] text-black-400",
            height
                ? css`
                      height: ${height}px;
                  `
                : "max-h-[calc(100vh-300px)]",
        )}
    >
        <ShapesIcon height={28} width={28} strokeWidth={1.3} />
        {text}
    </div>
);

export const Error = ({ height }: { height?: number }) => (
    <div
        className={clsx(
            "flex max-h-full w-full flex-1 flex-col items-center justify-center gap-3 space-x-4 py-4 text-[13px] text-black-300",
            height
                ? css`
                      height: ${height}px;
                  `
                : "min-h-[calc(100vh-300px)]",
        )}
    >
        <XOctagonIcon height={28} width={28} strokeWidth={1.3} />
        <div className="mt-2 text-[16px] font-[600] leading-none ">An error occured</div>
        <div className="mb-1 text-[13px] leading-none">Please refresh!</div>
    </div>
);
export const Pagination = ({
    page,
    totalPages,
    onChange,
}: {
    page: number | string;
    totalPages: number | string;
    onChange: (page: number) => void;
}) => (
    <div className="mt-20 flex items-center justify-center gap-4">
        <Button
            variant={"plain"}
            size={40}
            className={clsx("ml-2 flex h-[28px] min-w-[32px] justify-center rounded-[10px] py-[4px]", page === 1 ? "opacity-75" : "")}
            onClick={() => {
                if (page === 1) return;

                onChange(Number(page) - 1);
            }}
        >
            <ChevronLeftIcon className="mr-1" height={14} width={14} />
            Prev
        </Button>
        <div>
            {page} / {totalPages}
        </div>
        <Button
            variant={"plain"}
            size={40}
            className={clsx(
                "ml-2 flex h-[28px] min-w-[32px] justify-center rounded-[10px] py-[4px]",
                page === totalPages ? "opacity-75" : "",
            )}
            onClick={() => {
                if (page === totalPages) return;

                onChange(Number(page) + 1);
            }}
        >
            Next
            <ChevronRightIcon className="ml-1" height={14} width={14} />
        </Button>
    </div>
);
