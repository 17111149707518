// @ts-ignore
import * as Sentry from "@sentry/react";
import axios from "axios";
import { client } from "~/client";

import { eraseCookie } from "~/utils/cookie";
import { createCustomError } from "~/utils/error";
import Router from 'next/router'

export const axiosInstance = axios.create();

axiosInstance.defaults.withCredentials = true;
// Add a response interceptor
axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    errorHandler,
);


client.instance.defaults.withCredentials = true;


client.instance.interceptors.response.use((response) => {
    return response;
}, errorHandler);


function errorHandler(error: any) {
    if (error?.response?.status > 400) {
        console.log("Error in response interceptor");
        console.error(
            `🔗 URL: ${error.config.url} 🚨 Status: ${error.response.status} 📄 Status Text: ${error.response.statusText}`,
            `🚨 request-id:`,
            error.response.headers["x-request-id"],
        );

        // @ts-ignore
        Sentry.captureException(
            createCustomError("API Handling", "🚨 " + " URL: " + error.config.url + " Status: " + error.response.status, error),
            {
                tags: {
                    request_id: error?.response?.headers["x-request-id"],
                    backend_api_failing: error?.config?.url,
                    label: "backend_api_error",
                    status: error.response.status,
                    statusText: error.response.statusText,
                },
            },
        );
    }


    const router = Router.route;

    const nonRedirectPages = ["/", "/404", "/500", "/verify","/provider_login/[app]"];
    const isNonRedirectPage = nonRedirectPages.includes(router);

    console.log("Router", router, isNonRedirectPage);

    const isExecuteRequest = error?.config?.url?.includes("execute");

    if (error?.response?.status === 401 && !isNonRedirectPage && !isExecuteRequest) {
        // redirect to login
        window.location.href = `/`;
        eraseCookie("isLoggedIn");
        eraseCookie("skipOnboarding");
        eraseCookie("composio_email");
    }

    throw error;
}