import "~/styles/globals.css";

import * as Sentry from "@sentry/nextjs";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import whyDidYouRender from "@welldone-software/why-did-you-render";
import { type AppType } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React, { Suspense, useCallback, useEffect, useMemo } from "react";
//@ts-ignore
import NoSSR from "react-no-ssr";

import { useWindowSize } from "~/_website/ui/container/plaground/chat_box";
import { Toaster } from "~/design-system/atom/toaster";
import { MobileNotAvailableBanner } from "~/ui/components/base/not_available_on_mobile";
import { BACKEND_URL, IS_DEV } from "~/utils/baseUrl";
import { getCookie } from "~/utils/cookie";
import { INTERCOM_APP_ID, INTERCOM_SCRIPT, waitForIntercom } from "~/utils/scriptUtil";
import { setThemeHTMLFunction } from "~/utils/theme_utils";
import { ANALYTICS } from "~/utils/tracking";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { LoadingFull } from "~/ui/components/base/loadingFull";

import { client } from "../client/services.gen";

import clsx from "clsx";
import { plainBlackBackgroundCSS, scrollBarStyle } from "~/ui/style/common";
import { Sidebar } from "~/ui/containers/base/Sidebar";

client.setConfig({
    baseURL: BACKEND_URL,
    withCredentials: true,
    // throwOnError: true
});

const noop = () => {
    //
};

if (IS_DEV) {
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });
}

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onSuccess: noop,
        onError: noop,
    }),
    mutationCache: new MutationCache({
        onSuccess: noop,
        onError: noop,
    }),
    defaultOptions: {
        queries: {
            staleTime: 10 * 1000,
            refetchOnMount: "always",
            cacheTime: 120 * 1000,
            refetchOnWindowFocus: true,
        },
    },
});

if (typeof window !== "undefined") {
    //@ts-ignore
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: `${window.location.origin}/ingest`,
        ui_host: "https://app.posthog.com",
        //@ts-ignore
        segment: window?.analytics, // Pass window.analytics here - NOTE: `window.` is important
        capture_pageview: false, // You want this false if you are going to use segment's `analytics.page()` for pageviews
        // When the posthog library has loaded, call `analytics.page()` explicitly.
        loaded: (posthog) => {
            if (process.env.NODE_ENV === "development") posthog.debug();

            //@ts-ignore
            return window?.analytics?.page();
        },
        persistence: "cookie",
    });

    const user = {
        email: getCookie("composio_email") as string | undefined,
    };

    Sentry.setUser(user);

    Sentry.init({
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        tracesSampleRate: 1.0,
    });
}

const MyApp: AppType<any> = ({ Component, pageProps }) => {
    const router = useRouter();
    const { query } = router;

    const isRootPath = router.pathname === "/";

    useEffect(() => {
        if (query.cliKey) {
            const now = new Date();
            const item = {
                value: query.cliKey as string,
                expiry: now.getTime() + 3600000,
            };

            localStorage.setItem("cliKey", JSON.stringify(item));
        }
    }, [query.cliKey]);

    const windowSize = useWindowSize();

    const isMobileMode = useMemo(() => {
        if (router.pathname === "/") return false;
        if (router.pathname === "/google_signin") return false;
        if (router.pathname === "/redirect") return false;
        if (router.pathname.includes("onboarding")) return false;
        if (typeof window !== "undefined") {
            return window.innerWidth < 1020;
        }

        return false;
    }, [windowSize]);

    const isNonSidebarPage = useMemo(()=>{
        const paths = ["/","/google_signin","/redirect","/onboarding"]

        return paths.includes(router.pathname)
    },[router.pathname])

    useEffect(() => {
        setTimeout(() => {
            ANALYTICS.page("app_page", router.pathname, {
                title: document.title,
                url: document.location.href,
                //@ts-ignore
                ...(!!router?.search && {
                    // @ts-expect-error
                    search: router?.search,
                }),

                //@ts-ignore
                ...(!!router?.hash && {
                    // @ts-expect-error
                    hash: router.hash,
                }),
            });
        }, 1000);
        //@ts-ignore
    }, [router.pathname, router?.search, router?.hash]);

    useEffect(() => {
        (async () => {
            await waitForIntercom();
            const email = getCookie("composio_email");

            //@ts-ignore
            window?.Intercom?.("boot", {
                app_id: INTERCOM_APP_ID,
                ...(email && {
                    email: email,
                    user_id: email,
                }),
                action_color: "#1B53E4",
                background_color: "#1B53E4",
            });

            if (email) {
                const checkPlausible = setInterval(() => {
                    // @ts-ignore
                    if (window?.plausible) {
                        // @ts-ignore
                        window?.plausible('CLIENT_LOGIN');
                        clearInterval(checkPlausible);
                    }
                }, 100);
                ANALYTICS.identify(email, {
                    email: email,
                });
            }
        })();
    }, []);

    if (isMobileMode) {
        return (
            <NoSSR>
                <Head>
                    <title>Composio | Composio</title>
                    <meta name="theme-color" content="#000000" />
                    <script defer data-domain="composio.dev" data-api="https://pa.composio.dev/api/event" src="https://pa.composio.dev/js/script.tagged-events.js"></script>
                    {!isRootPath && <meta name="robots" content="noindex" />}
                </Head>
                <QueryClientProvider client={queryClient}>
                    <MobileNotAvailableBanner showLoader={true} />
                </QueryClientProvider>
            </NoSSR>
        );
    }

    const ParentComponent = useCallback(({children}:{children:React.ReactNode})=>{
        if(isNonSidebarPage){
            return children
        }
        return (
            <div className={clsx("flex min-h-[calc(100vh-10px)] overflow-x-hidden text-white", plainBlackBackgroundCSS,scrollBarStyle)}>
                <Sidebar />
                {children}
            </div>
        )
    }, [isNonSidebarPage])

    return (
        <NoSSR>
            <Head>
                <title>Composio.dev</title>
                <meta name="theme-color" content="#000000" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <script defer data-domain="composio.dev" data-api="https://pa.composio.dev/api/event" src="https://pa.composio.dev/js/script.tagged-events.js"></script>
                {!isRootPath && <meta name="robots" content="noindex" />}
                <script
                    dangerouslySetInnerHTML={{
                        __html: setThemeHTMLFunction,
                    }}
                />
            </Head>
            <ErrorBoundary fallback={<div>Error loading component</div>}>
                <Suspense fallback={<LoadingFull showLoader={true} />}>
                    <PostHogProvider client={posthog}>
                        <QueryClientProvider client={queryClient}>
                            <Script 
                            src="https://www.googletagmanager.com/gtag/js?id=G-CWE0CCJVF2" crossOrigin="anonymous" />
                            <Toaster />
                            <div id="login-prompt"></div>
                            <ParentComponent>   

                                <Component {...pageProps} />
                            </ParentComponent>
                            <div id="modal-root"></div>
                        </QueryClientProvider>
                    </PostHogProvider>
                </Suspense>
            </ErrorBoundary>
            <Script
                id="intercom"
                dangerouslySetInnerHTML={{
                    __html: INTERCOM_SCRIPT,
                }}
            />
        </NoSSR>
    );
};

export default MyApp;
